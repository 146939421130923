/* for wide screen screen */
@media only screen and (min-width: 1024px) {
    .top-blur-logo img{
        width: 135px;    
    }
    .home-header-wrap {
        padding: 10px 50px 0px 50px;
        background-color: #E1E8EF;
        color: #10527B;
    }
    .home-header {
        max-width: 1600px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: auto;
    }
    .home-hero-wrap {
        background-color: #E1E8EF;
        padding: 20px 50px 0px 50px;
        color: #10527B;
        font-size: 1.15rem;
    }
    .home-hero {
        max-width: 1600px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        margin :auto;
    }
    .home-products {
        display: flex;
        align-items: flex-start;
    }
    .home-section-wrap {
        padding: 40px 50px 0px 50px;
    }
    .home-section {
        max-width: 1600px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin :auto;
    }
    .home-section-item {
        max-width: 500px;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .cookies-notice-container {
        width: 92%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 0.85rem;
        font-weight: 200;
    }
    .cookies-notice .right {
        width: 500px;
    }
    .page-footer.with-cookies-notice {
        margin-top: 60px;
    }
}
/* for small screen */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .top-blur-logo img{
        width: 135px;    
    }
    .home-header-wrap {
        padding: 10px 20px 0px 20px;
        background-color: #E1E8EF;
        color: #10527B;
    }
    .home-header {
        max-width: 1600px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: auto;
    }
    .home-hero-wrap {
        background-color: #E1E8EF;
        padding: 20px 20px 0px 20px;
        color: #10527B;
        font-size: 1.15rem;
    }
    .home-hero {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    .home-section-wrap {
        padding: 40px 20px 0px 20px;
    }
    .home-section {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin :auto;
    }
    .home-section-item {
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .cookies-notice-container {
        width: 92%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        display: flex;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 0.85rem;
    }
    .cookies-notice .right {
        width: 500px;
    }
    .page-footer.with-cookies-notice {
        margin-top: 100px;
    }
}
/* for tablet and lower*/
@media only screen and (max-width: 767px) {
    .top-blur-logo img{
        width: 135px;    
    }
    .home-header-wrap {
        padding: 10px 15px 0px 15px;
        background-color: #E1E8EF;
        color: #10527B;
    }
    .home-header {
        max-width: 1600px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-becenttween;
        margin: auto;
    }
    .home-hero-wrap {
        background-color: #E1E8EF;
        padding: 20px 15px 0px 15px;
        color: #10527B;
        font-size: 1.15rem;
    }
    .home-section-wrap {
        padding: 40px 15px 0px 15px;
    }
    .home-section-item:nth-child(2) {
        margin-top: 30px;
    }
    .cookies-notice-container {
        width: 92%;
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 0.85rem;
    }
    .cookies-notice .right {
        width: 100%;
    }
    .page-footer.with-cookies-notice {
        margin-top: 200px;
    }
       
}
body {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    margin: auto;
}
.page-container {
    height:100vh; margin:0;
    display:flex; 
    flex-direction:column;
}
.home-nav-links a {
    margin-left: 30px;
    color: #10527B;
    text-decoration: none;
}
.home-nav-links a:hover {
    filter: brightness(150%);
}
.top-blur-logo img{
    width: 135px;    
}
.home-hero-text {
    margin-bottom: 20px;
}
.home-hero-img {
    min-height: 200px;
    width: auto;
}
#home-hero-img {
    position: relative;
    width: 100%;
    height: auto;
}
#home-hero-img-ads {
    position: absolute;
    z-index: 5;
}
.hero-title {
    font-family: Source Sans Pro,Helvetica Neue,Arial,Helvetica,sans-serif;
    font-size: 3rem;
    font-weight: 600;
    line-height: 1.1;
}
.home-benefits, .home-under-btn-text {
    font-size: 1rem;
    line-height: 1.5;
}
.checkmark {
    display:inline-block;
    width: 20px;
    height:20px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}
.checkmark_stem {
    position: absolute;
    width: 3px;
    height: 11px;
    background-color: #47ba77;
    left: 8px;
    top: 3px;
}
.checkmark_kick {
    position: absolute;
    width: 8px;
    height: 3px;
    background-color: #47ba77;
    left: 3px;
    top: 14px;
}
.home-product-benefit {
    margin-left: 5px;
}
.home-section-item img {
    height: 30px;
    width: auto;
}
.home-section-item-title {
    font-size: 1.15rem;
    font-weight: 600;
    color: #16527b;
    margin-bottom: 10px;
}
.home-section-item-links {
    line-height: 1.2;
}
.home-section-item-links a {
    color: #10527B;
}
.home-section-item-links a:hover {
    filter: brightness(150%);
}
.page-footer {
    margin-top:auto;
}
.page-footer-copyright {
    bottom: 0px;
    margin: auto;
    width: 100%;  
    font-size: 0.8em;
    padding-top: 50px;
    padding-bottom: 7px;
    text-align: center;
}
.page-footer a {
    color: #10527B;
}
.page-footer a:hover {
    filter: brightness(150%);
}
.cookies-notice {
	width: 100%;
	background: #032941;
	position:	fixed;
	bottom: 0px;
	z-index: 100;
	color: white;
}
.cookies-notice .left {
    text-align: left;
}
.cookies-notice .right {
    padding-top: 10px;
    padding-left: 20px;
    text-align: center;
}
.cookies-notice .right a {
    margin-left: 10px;
}
.cookies-notice a {
    text-decoration: underline;
    color: #FFF;
}
.cookies-notice button {
    margin-bottom: 1.3em;
}

/* helper */
.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flex-row-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
button.btn {
    padding: 10px 25px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: .7s;
}
button.btn:hover {
    transform: scale(1.05) perspective(1px);
}
button.btn.green {
    border: none;
    background-color: #47ba77;
    color: white;
    
}
button.btn.blue {
    border: none;
    background-color: #10527B;
    color: white;
}
button.btn.white-outline {
    background-color: transparent;
    border: 2px solid #f5f6f8;
    color: #f5f6f8;
}
button.btn.green-outline {
    background-color: transparent;
    border: 2px solid #47ba77;
    color: #47ba77;
}
a {
    cursor: pointer;
}
/* for hero animation */
.fade {
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}
.fade-out {
    opacity: 0;
}
.fade-in {
    opacity: 1;
}
.display-none {
    display: none;
}
